<template>
  <div>
    <title>SIM INVENTORY ~ PROCESS LIST DATA BY BATCH CODE</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Process List Data by Batch code
        <br />
        <h4>
          The following is a list of transaction data, a list of processes by
          batch code
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div class="row">
        <div class="col-md-12">
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Filter data</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  Show &nbsp; :
                  <select
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by Batch Code"
                    class="form-control"
                    v-model="namesearch"
                  />
                </div>
                <div class="col-xs-1">
                  <button
                    @click="searchdata()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
    
      <div class="row">
        <div class="col-xs-12">
          <router-link :to="'/add-produkproses/' + this.$route.params.id">
            <button title="Add Process Product" class="btn btn-success">
              <i class="fa fa-plus"></i> Detail Process Product
            </button>
          </router-link> <br /><br />
          <div class="box box-primary">
            <div class="box-header">
              <!-- <h3 class="box-title">Data Supplier</h3> -->
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  <label>Batch Code</label>
                </div>

                <div class="col-xs-1">
                  <label>:</label>
                </div>
                <div class="col-xs-7">
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    name="noprod"
                    v-model="noprod"
                    id="noprod"
                  />
                </div>
              </div>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Batch Code</th>
                      <th>Part Code</th>
                      <th>Qty</th>
                      <th>Process Name</th>
                      <th>Product Name</th>
                      <th>Copyright</th>
                      <th>Size</th>
                      <th>Color</th>
                      <th>SKU</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id_produk_d_proses"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.nomor_produk }}</td>
                      <td>{{ classdata.part_no }}</td>
                      <td>{{ classdata.qty }}</td>
                      <td>{{ classdata.nama_proses }}</td>
                      <td>{{ classdata.nama_produk }}</td>
                      <td>{{ classdata.copyright }}</td>
                      <td>{{ classdata.size }}</td>
                      <td>{{ classdata.color }}</td>
                      <td>{{ classdata.sku }}</td>
                      <td>
                        <router-link
                          :to="
                            '/add-schedule-buyer/' +
                              classdata.id_produk_d_proses
                          "
                        >
                          <button
                            title="Add Schedule Buyer"
                            class="btn btn-info"
                          >
                            <i class="fa fa-plus"></i> Schedule Buyer
                          </button>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination
  },
  data() {
    return {
      isShow: false,
      nmsrc: true,
      loading: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      noprod: "",
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
    this.noprod = this.$route.params.id;
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchData() {
      this.loading = true;
      sessionStorage.setItem("nop", this.noprod);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order_schedule_proses/getall_daftar_proses_berdasarkan_nomor_produk?length="+this.pageSize+"&page="+this.page+"&nomor_produk=" + this.$route.params.id
      const urlApiGETdetail =
        this.$apiurl +
        "buyer_order_schedule_proses/getall_daftar_proses_berdasarkan_nomor_produk?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&nomor_produk=" +
        this.$route.params.id;
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          this.list_paged = resp.data.data;
          this.count = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const paramsst = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        window.location.reload();
        this.loading = true;
        this.fetchData();
      } else {
        const tokenlogin = sessionStorage.getItem("token");
        // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order_schedule_proses/getall_daftar_proses_berdasarkan_nomor_produk?length="+this.pageSize+"&cari="+this.namesearch+"&page="+this.page+"&nomor_produk=" + this.$route.params.id
        const urlApiGETdetail =
          this.$apiurl +
          "buyer_order_schedule_proses/getall_daftar_proses_berdasarkan_nomor_produk?length=" +
          this.pageSize +
          "&cari=" +
          this.namesearch +
          "&page=" +
          this.page +
          "&nomor_produk=" +
          this.$route.params.id;
        axios
          .get(urlApiGETdetail, {
            paramsst,
            headers: { Authorization: tokenlogin }
          })
          .then((resp) => {
            this.list_paged = resp.data.data;
            this.count = resp.data.data.datatotalcount;
            this.loading = false;
          })
          .catch((err) => {
            // console.log(err.response);
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            }).then(function(isConfirm) {
              if (isConfirm) {
                window.location.href = "/";
              }
            });
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
